import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m2548 1313 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33 -4z
m-165 -10 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m280 0 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m70 -10 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m44 -9 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m108 -30 c17 -7 26 -13 22 -14 -12 0 -107 32 -107 36 0 6
50 -7 85 -22z m122 -50 c41 -19 73 -37 71 -39 -1 -2 -39 14 -83 35 -44 22 -76
39 -71 40 5 0 42 -16 83 -36z m123 -70 c0 -2 -9 0 -20 6 -11 6 -20 13 -20 16
0 2 9 0 20 -6 11 -6 20 -13 20 -16z m-666 -130 c177 -46 325 -192 369 -365
l14 -54 228 -5 227 -5 30 -99 c30 -103 33 -143 5 -90 -22 42 -96 107 -155 135
-29 14 -72 24 -114 27 l-68 4 0 -106 0 -106 -70 0 -70 0 -1 108 -2 107 -7 -80
c-13 -134 -13 -135 -76 -135 l-53 0 0 91 c0 50 -3 94 -6 97 -10 10 -93 -36
-135 -74 -23 -21 -48 -51 -57 -68 -26 -50 -24 -18 6 89 l28 100 72 3 73 3 -7
27 c-3 15 -23 61 -43 102 -47 95 -143 195 -227 236 -53 26 -71 29 -155 29 -85
0 -100 -3 -141 -27 -124 -73 -190 -224 -176 -407 3 -42 15 -103 26 -135 12
-33 21 -61 21 -63 0 -2 -35 -3 -77 -1 l-78 3 -11 50 c-61 260 105 534 366 606
82 22 184 24 264 3z m-1027 -506 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-10 -40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-15 -151 c-5 -147 9 -246 55 -372 13 -38 26 -81 29 -95 2 -16 1 -18 -2 -5 -9
31 -22 41 -36 26 -11 -10 -9 -11 6 -5 16 6 18 4 13 -10 -4 -10 -7 -23 -7 -29
0 -30 -30 51 -50 135 -28 119 -38 310 -20 413 7 41 14 75 15 75 1 0 0 -60 -3
-133z m2247 93 c0 -10 -3 -8 -9 5 -12 27 -12 43 0 25 5 -8 9 -22 9 -30z m18
-127 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-2106 -34 c19
-19 19 -51 -1 -72 -14 -16 -84 -19 -101 -5 -48 38 -20 98 46 98 22 0 43 -8 56
-21z m109 -19 l0 -40 40 0 c29 0 40 -4 40 -15 0 -12 -13 -15 -60 -15 l-60 0 0
55 c0 48 2 55 20 55 17 0 20 -7 20 -40z m257 -12 c28 -51 25 -70 -7 -50 -24
15 -80 16 -80 2 0 -5 -9 -10 -21 -10 -11 0 -19 3 -17 8 41 87 53 103 76 100
16 -2 31 -18 49 -50z m177 46 c19 -7 21 -34 4 -34 -7 0 -5 -5 5 -11 9 -5 17
-14 17 -19 0 -18 -32 -40 -59 -40 -31 0 -73 28 -54 36 10 4 10 10 0 25 -10 17
-9 23 7 35 21 15 54 18 80 8z m287 -15 c11 -18 11 -50 -1 -69 -6 -10 -27 -15
-72 -15 l-63 0 -1 54 0 53 64 -4 c41 -3 67 -9 73 -19z m188 -30 c37 -60 37
-59 11 -59 -11 0 -20 5 -20 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10 0 -5
-9 -10 -20 -10 -26 0 -26 1 11 60 18 29 38 50 48 50 11 0 31 -21 50 -51z m201
36 c0 -8 -3 -14 -7 -14 -26 4 -33 -4 -33 -41 0 -33 -3 -40 -19 -40 -16 0 -20
8 -23 43 -2 34 -6 41 -20 39 -11 -2 -18 3 -18 13 0 12 13 15 60 15 47 0 60 -3
60 -15z m173 -30 c31 -52 33 -65 12 -65 -8 0 -15 5 -15 10 0 6 -18 10 -40 10
-22 0 -40 -4 -40 -10 0 -5 -9 -10 -20 -10 -25 0 -25 0 8 60 35 65 58 66 95 5z
m258 34 c16 -8 18 -13 8 -25 -9 -11 -9 -14 0 -14 7 0 11 -10 9 -22 -4 -29 -48
-45 -85 -32 -32 11 -42 34 -16 35 16 1 16 2 1 6 -10 2 -18 13 -18 23 0 35 59
52 101 29z m109 -13 c0 -37 10 -56 31 -56 15 0 19 7 19 40 0 33 3 40 20 40 17
0 20 -7 20 -39 0 -46 -20 -71 -58 -71 -46 0 -72 26 -72 71 0 32 3 39 20 39 14
0 20 -7 20 -24z m282 -26 c9 -54 3 -60 -74 -60 l-58 0 0 55 0 56 62 -3 63 -3
7 -45z m75 -123 c-2 -23 -3 -1 -3 48 0 50 1 68 3 42 2 -26 2 -67 0 -90z
m-1468 -3 c109 -72 261 -72 355 0 37 28 98 38 77 13 -23 -29 -167 -87 -241
-97 -49 -8 -101 -24 -149 -48 l-74 -37 54 -8 c30 -4 104 -19 165 -32 177 -40
257 -30 283 34 8 17 17 31 22 31 17 0 9 -57 -16 -106 -29 -58 -77 -91 -147
-100 -49 -7 -176 21 -287 63 -39 15 -96 27 -141 30 -75 5 -75 5 -117 -32 -57
-48 -55 -30 1 26 26 25 91 68 153 101 59 31 109 61 111 66 1 5 -17 13 -42 16
-48 8 -175 66 -196 91 -11 13 -3 15 69 15 71 0 87 -3 120 -26z m783 -206 c4
-260 10 -286 71 -312 29 -13 17 -14 -138 -14 -128 0 -164 3 -144 11 15 5 35
24 45 41 17 28 19 57 22 269 l4 237 68 0 68 0 4 -232z m-1475 -175 c-2 -5 4
-16 15 -25 21 -21 26 -1 6 21 -11 11 -10 13 2 9 8 -2 20 -19 26 -36 5 -18 16
-38 23 -45 10 -10 11 -8 5 8 -4 11 -4 17 0 14 4 -4 9 -16 11 -28 2 -13 7 -31
10 -41 3 -10 8 -28 10 -41 5 -24 15 -39 15 -22 0 6 7 14 15 17 10 3 15 1 13
-7 -4 -17 47 -78 57 -69 5 4 5 1 1 -6 -4 -7 -1 -23 7 -36 19 -28 124 -99 135
-92 5 3 9 -1 9 -9 0 -10 -5 -11 -19 -4 -10 5 -18 7 -18 3 0 -4 -8 0 -19 10
-10 9 -25 14 -33 11 -41 -16 -201 177 -270 326 -9 21 -14 40 -11 43 9 8 15 7
10 -1z m410 -239 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-159
-44 c15 0 15 -2 2 -10 -13 -8 -12 -10 5 -11 11 -1 27 -3 35 -3 8 -1 14 -8 12
-15 -2 -9 3 -11 13 -7 10 4 14 2 9 -5 -4 -8 1 -9 17 -5 20 6 21 5 7 -4 -13 -8
-29 -5 -68 16 -27 14 -50 28 -50 29 0 2 -2 9 -3 17 -2 7 -2 10 0 6 3 -5 12 -8
21 -8z m37 -100 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m111 -34 c0 -20 -3 -22 -17 -13 -17 11 -14 17 17 37 0 0 0 -11 0 -24z
m1149 -151 c-17 -7 -56 -25 -87 -39 -31 -14 -82 -33 -114 -41 -32 -8 -68 -18
-79 -21 -20 -6 -20 -6 0 9 19 16 19 16 -5 11 -15 -4 -20 -2 -14 3 7 4 13 17
15 28 4 18 13 20 94 22 61 1 108 8 145 22 62 22 91 26 45 6z"/>
<path d="M1490 2706 c0 -21 5 -26 26 -26 21 0 25 4 22 23 -2 14 -11 23 -25 25
-19 3 -23 -1 -23 -22z"/>
<path d="M1875 2711 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M2068 2683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2310 2708 c0 -26 3 -29 28 -26 19 2 27 9 27 22 0 13 -10 22 -27 27
-26 7 -28 5 -28 -23z"/>
<path d="M2530 2705 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M2905 2711 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M3178 2723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3168 2683 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3520 2720 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10
-14 0 -25 -4 -25 -10z"/>
<path d="M3533 2683 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2808 1593 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
